import image1 from "../../images/Photoland.png";
import image2 from "../../images/SMS.png";
import image3 from "../../images/SM.png";
import image4 from "../../images/Sherpq.png";
import image5 from "../../images/Affiche_A3.png";
import image6 from "../../images/Minecraft.jpg";
import image7 from "../../images/ESIEA SMS.png";

export const ProjectList = [
  {
    id: 1,
    image: image1,
    title: "PhotoLand",
    category: "Web App",
  },
  {
    id: 2,
    image: image2,
    title: "Student Management System",
    category: "Desktop App",
  },
  {
    id: 3,
    image: image7,
    title: "Student Management System",
    category: "Desktop App",
  },
  {
    id: 3,
    image: image3,
    title: "MySudoKu",
    category: "Desktop App",
  },
  {
    id: 4,
    image: image4,
    title: "Sherpa Cloud",
    category: "Web App",
  },
  {
    id: 5,
    image: image6,
    title: "ESIEA CRAFT",
    category: "PFH",
  },
  {
    id: 6,
    image: image5,
    title: "Exprime-Toi",
    category: "PFH",
  },
];

export default ProjectList;
