import React, { useState } from "react";
import "./Navbar.css";
import MobileNav from "./MobileNav/MobileNav";
import { Link } from "react-scroll";
import { BiSolidDownload } from "react-icons/bi";
import logo from "../../assets/images/Logo.png";
import { IoCloseCircleSharp } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";

function NavBar() {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleDownload = async () => {
    try {
      const link = document.createElement("a");
      link.href = process.env.PUBLIC_URL + "/Resume/Brimal_MARTIN_CV.pdf";
      link.download = "Brimal_MARTIN_CV.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />

      <nav className="nav-wrapper">
        <div className="nav-content">
          <img className="logo" src={logo} alt="Bri's Portfolio" />

          <ul>
            <li>
              <Link
                activeClass="active"
                to="hero"
                smooth
                spy
                offset={-80}
                className="menu-item"
              >
                Home
              </Link>
            </li>

            <li>
              <Link
                activeClass="active"
                to="skills"
                smooth
                spy
                offset={-120}
                className="menu-item"
              >
                Skills
              </Link>
            </li>

            <li>
              <Link
                activeClass="active"
                to="work-exp"
                smooth
                spy
                offset={-100}
                className="menu-item"
              >
                Work Experience
              </Link>
            </li>

            <li>
              <Link
                activeClass="active"
                to="contact"
                smooth
                spy
                offset={-100}
                className="menu-item"
              >
                Contact Me
              </Link>
            </li>

            <button className="contact-btn" onClick={handleDownload}>
              <BiSolidDownload /> Download CV
            </button>
          </ul>

          <button class="menu-btn" onClick={toggleMenu}>
            <span style={{ fontSize: "1.8rem" }}>
              {openMenu ? <IoCloseCircleSharp /> : <GiHamburgerMenu />}
            </span>
          </button>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
