import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./ContactForm.css";

const ContactForm = () => {
  const formRef = useRef();
  const [showModal, setShowModal] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const response = await emailjs.sendForm(
        "service_i3yixtd",
        "template_9g9xgbp",
        formRef.current,
        { publicKey: "6E7if_KWf9T3StMYm" }
      );

      console.log("Email sent successfully:", response);

      setShowModal(true);

      setTimeout(() => {
        formRef.current.reset();
        setShowModal(false);
      }, 5000); //
    } catch (error) {
      console.error("Failed to send email:", error.text);
    }
  };

  return (
    <div className="contact-form-content">
      <form ref={formRef} onSubmit={sendEmail}>
        <div className="name-container">
          <input type="text" name="first_name" placeholder="First Name" />
          <input type="text" name="last_name" placeholder="Last Name" />
        </div>
        <input type="text" name="user_email" placeholder="Email" />
        <textarea type="text" name="message" placeholder="Message" rows={3} />

        <button type="submit">SEND</button>
      </form>

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <p>Your Message has been sent successfully. Thanks!!!</p>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
