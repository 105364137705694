import React from "react";
import "./MobileNav.css";
import logo from "../../../assets/images/Logo.png";

const handleDownload = async () => {
  try {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/Resume/Brimal_MARTIN_CV.pdf";
    link.download = "Brimal_MARTIN_CV.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
};
const MobileNav = ({ isOpen, toggleMenu }) => {
  const handleScroll = (sectionId) => {
    if (isOpen) toggleMenu();
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        className={`mobile-menu ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <div className="mobile-menu-container">
          <img className="logo" src={logo} alt="" />

          <ul>
            <li>
              <a
                href="#"
                onClick={() => handleScroll("hero")}
                className="menu-item"
              >
                Home
              </a>
            </li>

            <li>
              <a onClick={() => handleScroll("skills")} className="menu-item">
                Skills
              </a>
            </li>

            <li>
              <a onClick={() => handleScroll("work-exp")} className="menu-item">
                Work Experience
              </a>
            </li>

            <li>
              <a onClick={() => handleScroll("contact")} className="menu-item">
                Contact Me
              </a>
            </li>
            {isOpen && (
              <button className="contact-btn" onClick={handleDownload}>
                Download CV
              </button>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileNav;
