import React from "react";
import "./ContactMe.css";
import ContactInfoCard from "./ContactInfoCard/ContactInfoCard";
import ContactForm from "./ContactForm/ContactForm";
import image1 from "../../assets/images/email-icon.svg";
import image2 from "../../assets/images/github-icon.svg";
import image3 from "../../assets/images/Linkedin.png";

const ContactMe = () => {
  return (
    <section id="contact" className="contact-container">
      <h5>Contact Me</h5>

      <div className="contact-content">
        <div style={{ flex: 1 }}>
          <a href="mailto:brimal02@hotmail.com" style={{ color: "white" }}>
            <ContactInfoCard iconUrl={image1} text="brimal02@hotmail.com" />
          </a>

          <a href="https://github.com/Bri4real" style={{ color: "white" }}>
            <ContactInfoCard iconUrl={image2} text="Brimal MARTIN" />
          </a>
          <a
            href="https://www.linkedin.com/in/brimal-martin-178277269/"
            style={{ color: "white" }}
          >
            <ContactInfoCard iconUrl={image3} text="Brimal MARTIN" />
          </a>
        </div>

        <div style={{ flex: 1 }}>
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default ContactMe;
