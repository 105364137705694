import fron from "../assets/images/frontend-icon.png";
import back from "../assets/images/backend-icon.png";
import tool from "../assets/images/tools-icon.png";
import softs from "../assets/images/soft-skills-icon.png";

export const SKILLS = [
  {
    title: "Frontend",
    icon: fron,
    skills: [
      { skill: "HTML5", percentage: "80%" },
      { skill: "CSS3", percentage: "80%" },
      { skill: "Tailwind", percentage: "70%" },
      { skill: "JavaScript", percentage: "75%" },
      { skill: "React.js", percentage: "70%" },
      { skill: "Vue.js", percentage: "70%" },
    ],
  },
  {
    title: "Backend",
    icon: back,
    skills: [
      { skill: "Node.js", percentage: "65%" },
      { skill: "Express.js", percentage: "60%" },
      { skill: "Java", percentage: "70%" },
      { skill: "Spring Boot", percentage: "70%" },
      { skill: "Rest API", percentage: "70%" },
      { skill: "MySQL", percentage: "60%" },
      { skill: "MongoDB", percentage: "65%" },
    ],
  },
  {
    title: "Tools",
    icon: tool,
    skills: [
      { skill: "Git", percentage: "65%" },
      { skill: "Visual Studio Code", percentage: "75%" },
      { skill: "Scene Builder", percentage: "80%" },
      { skill: "Jira", percentage: "900%" },
      { skill: "Postman", percentage: "70%" },
      { skill: "IntelliJ IDEA by JetBrains", percentage: "80%" },
    ],
  },
  {
    title: "Soft Skills",
    icon: softs,
    skills: [
      { skill: "Problem-solving", percentage: "90%" },
      { skill: "Time Management", percentage: "90%" },
      { skill: "Creativity", percentage: "90%" },
      { skill: "Critical Thinking", percentage: "90%" },
      { skill: "Results Orientation", percentage: "90%" },
      { skill: "Adaptability", percentage: "90%" },
    ],
  },
];

export const WORK_EXPERIENCE = [
  {
    title: "Junior Web Developer at Peges Inc.",
    date: "August 2020 - August 2021",
    responsibilities: [
      "Technical Support",
      "Designing web applications that adapt and respond effectively to different screen sizes and devices.",
      "Planning and managing the structure and operations of databases, including considerations for efficiency, normalization, and regular maintenance.",
      "Rest Api using Express.Js",
      "Creating written materials that explain technical aspects of a project, often including code documentation, user guides, and system architecture documentation.",
      "Wordpress",
    ],
  },
  {
    title: "Intern Developer at Peges Inc.",
    date: "January 2020 - July 2020",
    responsibilities: [
      "Web Design in HTML/CSS & JavaScript",
      "Maintenance and Bug Correction",
      "Technical Support",
      "Technical Documentation Writing",
    ],
  },
];
