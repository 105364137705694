import React from "react";
import "./Hero.css";
import { Typewriter, Cursor } from "react-simple-typewriter";
import image1 from "../../assets/images/reactjs.png";
import image2 from "../../assets/images/vue.js.png";
import image3 from "../../assets/images/java.png";
import image4 from "../../assets/images/git.png";
import image5 from "../../assets/images/mongoDB.png";
import image6 from "../../assets/images/profilePicture.png";
import image7 from "../../assets/images/node js .png";
import image8 from "../../assets/images/python.png";
import image9 from "../../assets/images/mySQL.png";
import image10 from "../../assets/images/Csharp.png";
import image11 from "../../assets/images/TS.png";

const Hero = () => {
  return (
    <section id="hero" className="hero-container">
      <div className="hero-content">
        <h2>
          {" "}
          <Typewriter
            words={[
              "As a Full-Stack developer,",
              "I'm ready to transform creative visions",
              "into immersive digital experiences.😉😎",
            ]}
            loop={0}
            cursor={<Cursor />}
            cursorStyle="|"
            typeSpeed={100}
            deleteSpeed={30}
            delaySpeed={1000}
          />
        </h2>
      </div>

      <div className="hero-img">
        <div className="leftside">
          <div className="tech-icon">
            <img src={image1} alt="ReactJS" />
          </div>
          <div className="tech-icon">
            <img src={image2} alt="VUE.JS" />
          </div>
          <div className="tech-icon">
            <img src={image3} alt="JAVA" />
          </div>
          <div className="tech-icon">
            <img src={image4} alt="GIT" />
          </div>
          <div className="tech-icon">
            <img src={image5} alt="MOGO" />
          </div>
        </div>
        <div className="profilepicture">
          <img src={image6} alt="Brimal MARTIN" />
        </div>

        <div className="bottomside">
          <div className="tech-icon">
            <img src={image7} alt="Nodejs" />
          </div>
          <div className="tech-icon">
            <img src={image8} alt="python" />
          </div>
          <div className="tech-icon">
            <img src={image9} alt="MySQL" />
          </div>
          <div className="tech-icon">
            <img src={image10} alt="C#" />
          </div>
          <div className="tech-icon">
            <img src={image11} alt="TS" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
