import { FaCircleChevronLeft } from "react-icons/fa6";
import { FaCircleChevronRight } from "react-icons/fa6";
import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { ProjectList } from "../Projects/ProjectList";
import "./SchoolProject.css";

const SchoolProjects = () => {
  const [items, setItems] = useState(ProjectList);
  const sliderRef = useRef();

  const filterItems = (categoryItem) => {
    const updatedItems = ProjectList.filter(
      (curElem) => curElem.category === categoryItem
    );
    setItems(updatedItems);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slideRight = () => {
    sliderRef.current.slickNext();
  };

  const slideLeft = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className="project_container">
      <h2 className="section_title">Recent Projects</h2>

      <div className="content">
        <div className="arrow-right" onClick={slideRight}>
          <FaCircleChevronRight />
        </div>

        <div className="arrow-left" onClick={slideLeft}>
          <FaCircleChevronLeft />
        </div>

        <Slider ref={sliderRef} {...settings}>
          {items.map((item) => (
            <div key={item.title}>
              <div className="project_card">
                <div className="project_thumbnail">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="project_img"
                  />
                </div>

                <span className="project_category">{item.category}</span>
                <h3 className="project_title">{item.title}</h3>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default SchoolProjects;
